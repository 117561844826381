@import "../styles-variables";
@media screen and (min-width: 1600px) {
}

.text-color {
  color: #0193e0 !important;
}

.theme-wrapper {
  height: 100%;
  width: 100%;
}

.tp-card {
  max-width: 400px;
}

mat-sidenav-container {
  height: 100%;
  width: 100%;

  .toolbar {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    box-shadow: none;
    background: #2e333f;
    padding: 0 85px;
    @media only screen and (max-width: 991px) {
      padding: 0 15px;
    }
    .nav-button {
      margin: 0 10px 0 0;
    }

    .manu-name {
      flex: 1;
      font-size: 25px;
      text-align: center;
    }

    fa-icon {
      font-size: 24px;
    }

    .branding {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      @media only screen and (max-width: 991px) {
        padding-left: 0;
      }
      &.center {
        text-align: center;
      }

      img {
        float: left;
        position: relative;
        width: 50%;
        height: 65px;
        max-width: 100px;
        cursor: pointer;
      }
    }

    .mat-stroked-button {
      margin-right: 10px;
    }

    .spacer {
      flex: 1;
    }

    mat-select {
      margin: 0 0 0 20px;
      width: 40px;
      font-size: 14px;
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      .nav-button {
        min-width: 0;
        padding: 0 10px;
      }
    }

    .mat-primary {
      // background: url("http://simplescanstation.com/SimpleScanReports/images/topbarheader.jpg");
      color: #fff;
      background: #2e333f;
      box-shadow: none;
      height: 64px;
      // @media only screen and (min-width: 1600px) {
      //   height: 79px;
      // }
    }

    .mat-toolbar-single-row {
      padding: 0 10px !important;
      @media only screen and (max-width: 991px) {
        padding: 0 !important;
      }
    }

    .nav-bar-container {
      padding-left: 10px;
      @media only screen and (max-width: 991px) {
        padding: 0 !important;
      }
      .nav-bar {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        height: 125px;

        .list-item {
          font-size: 15px;
          padding: 13px 15px;
          color: #767e91;
          margin: 15px 5px 0;
          cursor: pointer;
          position: relative;

          &:hover {
            background: #ffffff;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            color: #0193e0;

            a {
              color: #0193e0;
            }
            .child-menu-container {
              display: block;
            }
          }

          .tab-name {
            padding-left: 5px;
          }

          a {
            color: #767e91;
          }

          .card-icons {
            font-family: "icomoon";
            position: relative;
            top: 1px;
          }
        }

        .selected-tab {
          color: #ffffff;

          a {
            color: #ffffff;
          }
        }

        .list-item:first-child {
          margin-left: 10px;
          @media only screen and (max-width: 991px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .content {
      flex: 1 0 auto;
      margin-top: 0;
      overflow: hidden;
      background: #efefef;
      // @media only screen and (min-width: 1600px) {
      //   margin-top: 79px;
      // }
      .current-page-name {
        padding: 0 103px;
        color: #0193e0;
        font-size: 21px;
        @media only screen and (max-width: 991px) {
          padding: 0 15px;
        }
      }

      .app-heading {
        width: 100%;
        border-bottom: 1px solid gainsboro;
        text-align: center;
        text-transform: uppercase;
        background-color: #0193e0;
        color: #ffffff;
        font-family: inherit;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          margin: 0;
        }
      }
    }

    .theme-profile {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .user-name {
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
  // @media (max-width: $toolbar-breakpoint) {
  //   .content {
  //     margin-top: 56px !important;
  //   }
  // }
}


.mat-list-base {
  float: left;
  .mat-list-item {
    width: auto;
    float: left;
    color: #ffffff;
    font-size: 15px;
  }
  .selected-tab {
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: rgba(0, 0, 0, 0.87);
  }
}

/*mat-nav-list {
  position: relative;
}*/
.list-parent {
  display: inline-block;
  position: relative;
  top: 7px;
}
.show-child-menu {
  position: absolute;
  top: 48px;
  left: 0;
  background: #fff;
  z-index: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding-top: 0;
  width: 200px;
  .child-menu {
    width: 100%;
    .mat-list-item-content {
      a {
        font-size: 14px;
        i {
          font-size: 14px;
        }
      }
    }
  }
}
.sub-menus {
  width: 100%;
  float: left;
  .mat-list-item.child-menu {
    color: #000 !important;
    font-size: 13px !important;
    i {
      font-size: 14px !important;
    }
  }
}
.tab-name {
  margin-left: 3px;
}

body {
  overflow-y: hidden !important;
}

.lock-icon{
  line-height: 0 !important;
  padding-left: 8px !important;
  font-size: 26px !important;
}

