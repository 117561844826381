@import "styles-variables";
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "styles-reset";
@import "../node_modules/@angular/material/theming";
@include mat-core();
@import "themes/default-theme.scss";
@import "themes/light-theme.scss";
@import "themes/black-theme.scss";
@import "themes/nature-theme.scss";
@import "styles-reset.scss-theme";
@import "app/app.component.scss-theme";
@import "app/shared/big-input/big-input.component.scss-theme";
@import "app/static/about/about.component.scss-theme";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~ngx-smart-modal/ngx-smart-modal";
@import "app/app.component";
@mixin custom-components-theme($theme) {
  @include anms-styles-reset-theme($theme);
  @include anms-app-component-theme($theme);
  @include anms-about-component-theme($theme);
  @include anms-big-input-component-theme($theme);
}

.default-theme {
  @include angular-material-theme($anms-theme);
  @include custom-components-theme($anms-theme);
}

.light-theme {
  @include angular-material-theme($anms-light-theme);
  @include custom-components-theme($anms-light-theme);
}

.black-theme {
  @include angular-material-theme($anms-black-theme);
  @include custom-components-theme($anms-black-theme);
}

.nature-theme {
  @include angular-material-theme($anms-nature-theme);
  @include custom-components-theme($anms-nature-theme);
}

.default-theme .mat-raised-button.mat-accent {
  background-color: #0193e0;
}
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
/*ngx Modal CSS*/
.modal-header {
  border: none;
  padding: 10px 10px 0 15px;
  font-size: 22px;
  text-align: center;
  display: block;
}

.delete-modal-user .modal-header {
  border-bottom: 1px solid #d6d6d6;
  background: #0193e0;
  color: #fff;
  padding: 6px;
  font-size: 18px;
  font-weight: bold;

  .close {
    position: absolute;
    right: 25px;
    top: 22px;
    color: #fff;
    opacity: 1;
    padding: 0;
  }
}
.delete-modal-template .modal-header {
  border-bottom: 1px solid #d6d6d6;
  background: #0193e0;
  color: #fff;
  padding: 6px;
  font-size: 18px;
  font-weight: bold;

  .close {
    position: absolute;
    right: 25px;
    top: 22px;
    color: #fff;
    opacity: 1;
    padding: 0;
  }
}

.delete-modal-user .modal-footer {
  justify-content: center;
}
.delete-modal-template .modal-footer {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.modalHeader {
  padding: 6px 0;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.modal-footer {
  border: none;
  padding: 0;
}

button.close {
  float: right;
  font-size: 21px;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.7;
  padding-bottom: 2px;
  outline: none;
}

button.close:hover {
  opacity: 0.9;
  color: #000000;
}

.fade {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 20%;
}
.copy-template .fade {
  padding-top: 10%;
}
.mydp {
  position: relative;
  top: 0 !important;
  width: 20% !important;
  float: right !important;
}

.mydp .selector {
  position: fixed !important;
}
.mydp .selectiongroup .selection {
  height: 24px !important;
}

h1 {
  font-size: 15px;
  line-height: 20px;
}

.modal-content {
  width: 500px;
  max-width: 500px;
  margin-top: -10%;
  color: #353535;
  opacity: 1;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.modal-body {
  padding: 15px;
  max-height: 400px;
  overflow: auto;
}

.modal-backdrop {
  background-color: transparent !important;
}

.modal-backdrop.fade {
  z-index: -1;
}

.heading {
  padding: 15px;
  text-align: center;

  img {
    min-width: 239px;
    max-width: 239px;
  }
}

.content-block {
  width: 100%;
  float: left;
  padding: 5px 0;
}

.button-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.apply-temp-checkbox .checkbox-list {
  .mat-checkbox-layout{
    .mat-checkbox-inner-container {
      height: 12px !important;
      width: 12px !important;
    }
  }
}

.float-left {
  float: left;
}

.margin-10-0 {
  margin: 10px 0;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-39{
  margin-bottom: 39px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-bottom-1{
  margin-bottom: 1px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.text-center {
  text-align: center;
}
.border-left{
  border-left: 5px solid rgb(246, 249, 250) !important;
}

.part-number-table-container,
.report-table-container,
.table-view-table-container,
.users-container {
  .mat-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 0 3px;

    mat-icon {
      font-size: 18px;
    }
  }
}
.areas-container {
  .mat-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 0 3px;

    mat-icon {
      font-size: 18px;
    }
  }
}

  .locations-container {
    .mat-icon-button {
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin: 0 3px;

      mat-icon {
        font-size: 18px;
      }
    }
  }

.mat-simple-snackbar {
  display: block !important;
  text-align: center;

  span {
    color: #fff;
  }
}

.default-theme .mat-progress-spinner circle,
.default-theme .mat-spinner circle {
  stroke-width: 5% !important;
  stroke: #0193e0;
}

.mydp {
  position: relative;
  top: 6px;
  width: 100% !important;
  float: right !important;
  height: 50px;
}

.mydp .selector {
  position: absolute !important;
}

.mydp .selection {
  padding-top: 8px !important;
}

.mydp .selectiongroup {
  padding-top: 8px !important;
}

.mydp .daycell{
  background-color: #ffffff !important;
  color: #595959 !important;
  .mydp .disabled {
    color: #CCC !important;
  }
}

.mydp .prevmonth {
  color: #CCC !important;
}

.mydp .nextmonth {
  color: #CCC !important;
}

.mydp .disabled {
  cursor: not-allowed !important;
  color: #CCC !important;
  background-color: #ffffff !important;
}

.mydp .selectedday {
  background-color: #2196f3 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  .datevalue {
    background: transparent !important;
  }
}

.mydp .headerbtncell {
  background-color: #ffffff !important;
  color: #595959 !important;
}

.mydp .headerlabelbtn {
  background-color: #ffffff !important;
}

.mydp .header td {
  background-color: #ffffff !important;
}

.mydp .weekdaytitle {
  background-color: #ffffff !important;
}

.padding-0 {
  padding: 0;
}

.margin-right-5{
  margin-right: 5px;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-0-6 {
  padding: 0 6px;
}

.font-bold {
  font-weight: bold;
}
.color-green{
  color: lawngreen;
}
.color-red{
  color: red;
}

.disable-submit {
  cursor: not-allowed;
}

input[disabled] {
  cursor: not-allowed;
}

.display-flex {
  display: flex;
}

.width-100 {
  width: 100%;
}

.padding-0-15{
  padding: 0 15px;
}

.width-90 {
  width: 90%;
}

.width-75{
  width: 75%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-30{
  width: 30%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-25{
  width: 25%;
}

.width-32 {
  width: 32.65%;
}

.width-16 {
  width: 16.66%;
}

.width-49 {
  width: 48.5%;
}

.width-12 {
  width: 12%;
}

.width-18 {
  width: 18%;
}

.width-19{
  width: 19%;
}

.width-14 {
  width: 14%;
}

width-5{
  width: 5%;
}

.width-12 {
  width: 12%;
}

.font-size-18 {
  font-size: 18px;
}


.font-size-10 {
  font-size: 10px;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}
.margin-right-15{
  margin-right: 15px;
}

.pure-checkbox input[type="checkbox"] + label:before {
  box-sizing: content-box;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: -9px;
  text-align: center;
  transition: all 0.4s ease;
  border: 1px solid #0193e0;
}

.pure-checkbox input[type="checkbox"]:checked + label:after {
  display: block;
  font-family: FontAwesome;
  position: absolute;
  content: "\f00c" !important;
  font-size: 15px;
  left: -3px;
  top: 3px;
  height: 20px;
  color: #0193e0;
  transform: rotate(-5deg) scale(1.1) !important;
}

.selected-list .c-list .c-token {
  background: #0193e0;
  color: white;
}

.selected-list .c-list .c-token .c-label {
  color: white;
}

.selected-list .c-list {
  float: left;
  /* color: red; */
  padding: 0;
  margin: 0;
  height: 25px;
  display: -webkit-box;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 1; */
  text-overflow: ellipsis;
  width: calc(100% - 20px);
}

button:disabled,
input[type="text"]:disabled {
  cursor: not-allowed;
}

.part-number-details {
  .mat-card {
    padding: 0;
  }
}

.default-theme .error-notification-overlay {
  background: #bd362f;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-15{
padding: 15px;
}

.padding-5{
  padding: 5px;
}
.padding-30{
  padding: 30px;
}
.padding-left-right-15 {
  padding: 2px 15px;
}
padding-15-15{
  padding: 0 15px;
}

.margin-right-1 {
  margin-right: 1%;
}

.margin-bottom-12{
  margin-bottom: 12px;
}

.margin-15{
  margin: 15px 0px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-right-0 {
  padding-right: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-0-5 {
  margin: 0 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-15{
  padding-bottom: 15px;
}

.print-modal .mat-form-field-infix {
  border-top: 10px solid transparent;
  padding-bottom: 10px !important;
}

.padding-0{
  padding: 0px;
}

.margin-0{
  margin: 0px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

#pagination,
#paginationAssignMachine,
#paginationOperator,
#paginationUnAssignMachine {
  padding: 0;
  display: inline-block;
  margin: 10px 0;
  border-radius: 2px;

  ul {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }

  ul li {
    border: 1px solid #d6d6d6;
    margin-left: -2px;
    height: 35px;
    text-align: center;
    min-width: 35px;
    line-height: 27px;

    &:hover {
      background: transparent;
    }
  }

  .ngx-pagination {
  }

  .pagination-previous {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  .pagination-next {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  a {
    color: #606c71;

    &:hover {
      background: transparent;
    }
  }

  .disabled {
    padding: 0.1875rem 0.625rem;
    margin-left: 0.9px;
    color: #cacaca;
    cursor: not-allowed;
  }

  .ngx-pagination .current {
    background: #0193e0;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.default-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0193e0;
}

.default-theme .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: #0193e0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: right;
}

.selected-list .c-btn {
  top: 3px;
}

.reports-container .mat-form-field-wrapper {
  padding: 0;
}


.cdk-overlay-connected-position-bounding-box {
  height: 250px !important;
  width: 250px !important;
  right: 0 !important;
  .cdk-overlay-pane {
    position: absolute !important;
    right: 120px !important;
    @media only screen and (max-width: 991px) {
      right: 0 !important;
    }
    .mat-menu-panel {
      height: auto !important;
      width: 200px !important;
      position: relative;
      top: 10px;
    }
  }
}

.site-panel {
  position: absolute;
}
.dropdownSelect {
  position: absolute;
  bottom: 30px;
}

.mat-tab-list {
  .mat-tab-label {
    padding: 0 15px;
    min-width: 120px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 2px;
    color: black;
  }
}
.parent-slide-toggle {
  min-height: 60px;
  display: flex;
  .slide-toggle-container {
    display: flex;
    align-items: center;
    padding-right: 0;
    mat-label {
      font-size: 12px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
}

.dropdown-list-container {
  .pure-checkbox input[type="checkbox"]+label:before {
    display: none;
  }
  .cuppa-dropdown {
    margin-bottom: 27px;
    .c-btn {
      border: 1px solid #ccc;
      min-height: 50px;
      display: flex;
      align-items: center;
      span{
        color: dimgray;
      }
    }
    //.list-area {
    //  .ng-star-inserted {
    //    max-height: 235px !important;
    //  }
    //}
    .list-area{
      .filter-select-all{
        display: none;
      }
    }
  }
}

.report-dropdown {
  .pure-checkbox input[type="checkbox"]+label:before {
    display: none;
  }
  .cuppa-dropdown {
    margin-bottom: 27px;
    .c-btn {
      border: 1px solid #ccc;
      min-height: 50px;
      display: flex;
      align-items: center;
      span{
        color: dimgray;
      }
    }
    .list-area {
      .ng-star-inserted {
        max-height: 235px !important;
      }
    }
    .list-area{
      .filter-select-all{
        display: none;
      }
    }
  }
}

.log-dropdown {
  .pure-checkbox input[type="checkbox"]+label:before {
    display: none;
  }
  .cuppa-dropdown {
    margin-bottom: 27px;
    .c-btn {
      border: 1px solid #ccc;
      min-height: 50px;
      display: flex;
      align-items: center;
      span{
        color: dimgray;
      }
    }
    .list-area {
      .ng-star-inserted {
        max-height: 110px !important;
      }
    }
    .list-area{
      .filter-select-all{
        display: none;
      }
    }
  }
}


.template-dropdown {
  .pure-checkbox input[type="checkbox"]+label:before {
    display: none;
  }
  .cuppa-dropdown {
    margin-bottom: 27px;
    .c-btn {
      border: 1px solid #ccc;
      min-height: 50px;
      display: flex;
      align-items: center;
      span{
        color: dimgray;
      }
    }
    .list-area {
      .ng-star-inserted {
        max-height: 240px !important;
      }
    }
    .list-area{
      .filter-select-all{
        display: none;
      }
    }
  }
}


.dropdown-list-container.machine-dropdown-filter {
  .cuppa-dropdown {
    .c-btn {
      min-height: 40px;
      margin: 2px 0;
      background-color: white;
      border-radius: 4px;
      span{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .list-area{
      .c-input{
        font-size: 13px;
      }
      .filter-select-all{
        display: none;
      }
    }
    .list-message{
      font-size: 14px;
    }
    ul {
      li {
        font-size: 12px;
      }
    }
  }
}

.mat-radio-list-container {
  min-height: 78px;
  .inner-container {
    min-height: 70px;
    display: flex;
    align-items: center;
    mat-radio-group {
      width: 100%;
      mat-radio-button {
        margin-right: 15px;
      }
    }
  }
}

.default-theme .mat-tab-group.mat-primary .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0193E0;
}

.default-theme .mat-chip.mat-standard-chip {
  background: #0193E0;
  color: white;
}

.default-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: #0193E0;
  .mat-expansion-panel-header-title {
    color: #fff;
    font-weight: bold;
  }
  .mat-expansion-indicator::after {
    color: #ffffff;
  }
}

.mat-expansion-panel-body {
  padding: 0;
}

.mat-expanded .mat-expanded {
  background: #0193E0 !important;
  .mat-expansion-panel-header-title {
    color: #fff;
    font-weight: bold;
  }
  .mat-expansion-indicator::after {
    color: #ffffff;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.template-config .mat-expansion-panel-body {
  border: 1px solid lightgray !important;
  border-radius: 4px !important;
}

  //Dropdown SCSS for displaying dropdown above
.bottom-side-dropdown {
  .dropdown-list {
    bottom: 50px;
    .arrow-up, .arrow-down {
      display: none;
    }
    .list-area {
      > div:nth-child(3) {
        max-height: 250px !important;
      }
      .filter-select-all {
        padding: 10px;
        text-align: center;
      }
    }
  }
}

.remove-focus {
  &:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }
}

.remove-focuss {
  &:focus {
    box-shadow: none;
  }
}

.tag-input-label {
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: inline-flex;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 25px;
  height: 1px;
  background-color: #0193e0;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  padding: 5px 10px;
  margin: 4px;
  position: relative;
  overflow: hidden;
}

.report-input-label {
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: inline-flex;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 35px;
  height: 1px;
  background-color: #0193e0;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  padding: 5px 10px;
  margin: 4px;
  position: relative;
  overflow: hidden;
}

.to-height {
  .mat-form-field-flex {
    height: 56px !important;
  }
}

.time-picker-header,
.time-picker-clock > button.active,
.time-picker-clock-origin,
.time-picker-clock-arrow {
  background: #0193e0 !important;
}

.time-picker-selected-time,
.time-picker-selected-ampm,
.time-picker-selected-time div.selected,
.time-picker-selected-ampm div.selected {
  color: #fff !important;
}

.time-picker-selected-ampm div {
  color: #000 !important;
}

.time-picker-footer {
  button {
    background: transparent !important;
    text-transform: uppercase;
    font-weight: bold;
    color: #0193e0;
  }
}

.mydp .caltable {
  font-size: 13px !important;
}

.nsm-dialog {
  margin-top: 15% !important;
}

.nsm-content {
  padding: 0 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  @media only screen and (min-width: 991px) {
    width: 700px !important;
    max-width: 700px !important;
  }
  margin: 0 !important;
}

.nsm-dialog {
  @media only screen and (min-width: 991px) {
    width: 700px !important;
    max-width: 700px !important;
  }
}

.nsm-dialog-btn-close {
  display: none !important;
}
.delete-heading {
  padding: 10px;
  background: #0193e0;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .close-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}
.pop-up-button{
  text-align: center;
  padding-bottom: 15px;
  padding-right: 0;
  line-height: 36px;
}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content
{
  padding: 0px 15px !important;
}
.auto-width{
  width:auto !important;
}

.padding-b-0{
  padding-bottom: 0px !important;
}
.padding-t-0{
  padding-top: 0px !important;
}

.padding-15-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.display-none{
  display: none;
}

.padding-l-5{
  padding-left: 4px !important;
}

.button-print-export{
  margin-right: 18px;
}
.mat-label-multiselect{
  position: absolute;
  display: block;
  top: -6px;
  left: 18px;
  background: white;
  color: #2e3861;
  font-size: 12px;
}

.new-fields-update{
  display: flex !important;
}

.new-fields-non-update{
  display: none !important;
}