@import "~@angular/material/theming";

@mixin anms-big-input-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  anms-big-input {
    .big-input {
      input {
        color: mat-color($foreground, text);
        background-color: mat-color($background, card);
      }

      &.has-focus {
        box-shadow: 0 0 15px 2px rgba(mat-color($accent), 0.4),
          0 0 15px 2px rgba(mat-color($accent), 0.4),
          0 0 15px 2px rgba(mat-color($accent), 0.4);
      }
    }
  }
}
